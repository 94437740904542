import React from 'react';
import styles from './grid.module.css';
import { Blurhash } from "react-blurhash";

import {
    Link
} from "react-router-dom";


class Thumbnail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            id: props.id,
            thumbnail: props.thumbnail,
            blurhash: props.blurhash,
            title: props.title,
        };
    }


    onImageLoad(event) {
        this.setState({ loaded: true });
    }


    render() {
        return (
            <Link to={'/view/' + this.state.id} className={styles.thumbnailRoot}>


                <div className={styles.thumbnailImageContainer}>
                    <div className={styles.thumbnailMobileText}>
                        {this.state.title}
                    </div>
                    <div className={styles.imagePlaceholder} style={this.state.loaded ? {
                        opacity: 0,
                        transition: 'opacity 0.5s'
                    } : {
                        opacity: 1,
                        transition: 'opacity 0.5s'
                    }}>
                        <Blurhash
                            hash={this.state.blurhash}
                            width={"100%"}
                            height={"100%"}
                            resolutionX={32}
                            resolutionY={32}
                            punch={1}
                            style={{ zIndex: 2 }}
                        />
                    </div>
                    <div className={styles.imageScaleContainer}>
                        <img className={styles.image} src={this.state.thumbnail} alt={this.state.title} onLoad={this.onImageLoad.bind(this)} ></img>
                    </div>
                </div>


            </Link>
        )
    }
}

export default Thumbnail