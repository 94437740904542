import React, { useState, useEffect } from "react";
import styles from "./info.module.css"
import Footer from '../Footer/footer'

function Info() {
    const [isMobile, setMobile] = useState(
        window.matchMedia("(max-width: 700px)").matches
    );

    const updateMedia = () => {
        setMobile(window.matchMedia("(max-width: 700px)").matches);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const text =
        `Olen oululaislähtöinen, sittemmin Pohjois-Savoon asettunut kuvittaja, sarjakuvataiteilija ja graafinen suunnittelija.

Born and raised in Oulu, I am an illustrator, cartoonist and graphic designer who has since settled in Northern Savonia.`
    return (
        <div className={styles.infoContainer}>

            <p>{text}</p>
            <img src="/pentti_otsamo.jpg" alt="Pentti Otsamo"></img>

            <Footer />

        </div>
    )
}

export default Info;