import { SocialLink } from '../Header/socialbuttons';
import styles from './footer.module.css';


function FooterItems() {
    return <>
        <div className={styles.socialLinks}>
            <SocialLink type="freelancegraafikot"></SocialLink>
            <SocialLink type="kuvittajat"></SocialLink>
            <SocialLink type="instagram"></SocialLink>
            <SocialLink type="grafia"></SocialLink>
            <SocialLink type="behance"></SocialLink>
        </div>
        <div className={styles.email}>
            <a href="mailto:po@penttiotsamo.com">po@penttiotsamo.com</a>
        </div>
    </>
}

function Footer() {
    return (
        <div>
            <div className={styles.divider}></div>

            <FooterItems />
        </div>
    )
}

function MarginFooter() {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.divider}></div>

            <FooterItems />
        </div>
    )
}

export { MarginFooter };

export default Footer;