import React from 'react';
import Thumbnail from './thumbnail';
import styles from './grid.module.css';
import { getProjects } from '../../api';

class Grid extends React.Component {

    constructor(props) {
        super(props)
        this.state = { projects: [] }

    }

    componentDidMount() {
        window.scrollTo({ top: 0 })
        getProjects().then(projects =>
            this.setState({ projects: projects })
        )
    }

    render() {
        return (
            <div className={styles.grid}>
                {this.state.projects.map((project, i) => (
                    <Thumbnail
                        key={i}
                        id={project.id}
                        thumbnail={project.thumbnail}
                        blurhash={project.blurhash}
                        title={project.title}
                    >
                    </Thumbnail>
                ))}

                <div className={styles.fillerThumbnail}></div>
                <div className={styles.fillerThumbnail}></div>
                <div className={styles.fillerThumbnail}></div>
                <div className={styles.fillerThumbnail}></div>
            </div>
        )
    }
};

export default Grid