export const getProject = async (id) => {
    var apiURL = `/api/project/${id}`;
    let data = await new Promise((resolve, reject) => {
        fetch(apiURL)
            .then(res => {
                try {
                    res.json().then(data => { resolve(data) })
                } catch (error) {
                    reject(error);
                }
            })
    }

    )
    return data
}

export const getProjects = async () => {
    var apiURL = `/api/project`;
    let data = await new Promise((resolve, reject) => {
        fetch(apiURL)
            .then(res => {
                try {
                    res.json().then(data => { resolve(data) })
                } catch (error) {
                    reject(error);
                }
            })
    }
    )
    return data
}

export const getProjectImages = async (id) => {
    var apiURL = `/api/project/${id}/images`;
    let data = await new Promise((resolve, reject) => {
        fetch(apiURL)
            .then(res => {
                if (res.status !== 200) {
                    resolve([])
                    return;
                }
                try {
                    
                    res.json().then(data => { 
                        
                        if (data) {
                            resolve(data)
                        }
                        else {
                            resolve([])
                        }
                    })
                } catch (error) {
                    reject(error);
                }
            })
    }


    )
    return data
}