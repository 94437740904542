import React, { Component } from 'react'

// import Swiper core and required modules
import SwiperCore, { Zoom, Pagination, Navigation, Thumbs, Lazy, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles

// swiper core styles
import 'swiper/css';

import "./swiper.css"
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import 'swiper/css/lazy';
import 'swiper/css/zoom';

import { getProjectImages } from '../../api';


// install Swiper modules
SwiperCore.use([Lazy, Zoom, Pagination, Navigation, FreeMode, Thumbs]);


class Carousel extends Component {


  constructor(props) {
    super(props);
    this.state = { images: props.images, thumbsSwiper: null, width: 0, height: 0, smallWindow: false };
    this.onChangeSlide = props.onChangeSlide;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.calculateSlidesPerView = this.calculateSlidesPerView.bind(this);
    this.calculateSlidesPerView()
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);


    getProjectImages(this.props.id).then(images => {
      this.setState({ images: images })
      this.forceUpdate();
    })


  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    this.calculateSlidesPerView()
  }

  calculateSlidesPerView() {
    //this.setState({slidesPerView: this.state.width < 1000 ? Math.floor(this.state.width/100) : Math.floor(this.state.width/200)});
    //this.setState({ slidesPerView: 4 })
  }

  render() {
    return (
      <>
        <div className="main-swiper">

          <div className="swiper-button-prev"></div>
          {this.state.images.length === 0 ? null :
            <Swiper
              itemRef=''
              color={"white"}
              preloadImages={false}
              observer={true}
              effect={""}
              spaceBetween={50}
              navigation={{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }}
              roundLengths

              centeredSlides
              onClick={(swiper, event) => {
                let rect = swiper.el.getBoundingClientRect();
                let midx = rect.x + rect.width / 2;
                var x = midx;
                if ('changedTouches' in event) {
                  x = event.changedTouches[event.changedTouches.length - 1].pageX;
                }
                else if ('x' in event) {
                  x = event.x;
                }

                let p = (x - midx) / rect.width * 2.0;
                if (p < -0.4) {
                  swiper.slidePrev();
                }
                else if (p > 0.4) {
                  swiper.slideNext();
                }

              }
              }
              onInit={(swiper) => this.onChangeSlide(swiper.activeIndex)}
              onActiveIndexChange={(swiper) => this.onChangeSlide(swiper.activeIndex)}
              lazy={{
                checkInView: false,
                loadPrevNext: true,
                loadPrevNextAmount: 1,
                loadOnTransitionStart: true,

              }}
              thumbs={{ swiper: this.state.thumbsSwiper }}
              className="swiper-root"
            >
              {this.state.images?.map((image, i) => (
                <SwiperSlide
                  key={i}
                  zoom>
                  <img data-src={"/" + image.image} className="swiper-lazy" alt={`${this.props.projectTitle} ${i}`} />
                  <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>

                </SwiperSlide>
              ))}


            </Swiper>
          }
          <div className="swiper-button-next"></div>

        </div>
        {this.state.images.length <= 1 ? null :
          <Swiper
            onSwiper={(e) => this.setState({ thumbsSwiper: e })}

            onInit={(e) => { e.el.firstChild.style.transform = "translate3d(0,0,0)" }}
            spaceBetween={10}

            threshold={20}
            centeredSlidesBounds

            lazy={{
              loadPrevNext: true,
              loadPrevNextAmount: 4,
              loadOnTransitionStart: true
            }}
            slidesPerView={4}
            slideToClickedSlide

            freeMode={{
              enabled: false
            }}
            onClick={() => { }}
            //centeredSlidesBounds


            preventClicks={false}
            className="thumb-swiper-root"

          >

            {this.state.images?.map((image, i) => (
              <SwiperSlide key={i}>

                <img data-src={"/" + image.image} className="swiper-lazy" alt={`thumb-${this.props.projectTitle}`} />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-black"></div>

              </SwiperSlide>
            ))}
          </Swiper>
        }
      </>
    )
  }
};



export default Carousel