import React from 'react'
import { useState } from 'react';
import styles from "./header.module.css"
import { Link } from 'react-router-dom';


const SocialLink = (props) => {
    switch (props.type) {
        case "instagram":
            return (
                <a className={styles.linkSocial + ' ' + styles.linkSocialInstagram}
                    href="https://www.instagram.com/penttiotsamo/" >
                    <img src="/logo-instagram.png" className={styles.imageSocial} alt="Instagram"></img>
                </a>
            )

        case "kuvittajat":
            return (
                <a className={styles.linkSocial}
                    href="https://kuvittajat.fi/portfolio/pentti-otsamo" >
                    <img src="/kuvittajat-site-logo@2x.png" className={styles.imageSocial} alt="Kuvittajat Ry"></img>
                </a>
            )

        case "behance":
            return (
                <a className={styles.linkSocial}
                    href="https://www.behance.net/potsamo" >
                    <img src="/logo-behance.png" className={styles.imageSocial} alt="Behance"></img>
                </a>
            )

        case "freelancegraafikot":
            return (
                <a className={styles.linkSocial}
                    href="https://freelancegraafikot.fi/user/pentti-otsamo" >
                    <img src="/logo-freelancegraafikot.svg" className={styles.imageSocial + ' ' + styles.imageSocialFreelancegraafikot} alt="Freelance-Graafikot"></img>
                </a>
            )
        case "grafia":
            return (
                <a className={styles.linkSocial}
                    href="https://grafia.fi/tekijat/pentti-otsamo-2/" >
                    <img src="/logo-grafia.png" className={styles.imageSocial} alt="Grafia"></img>
                </a>
            )

        default:
            return ("")
    }

}


const SocialMenu = ({ onMenuOpen }) => {
    const [menuOpen, setMenu] = useState(false);
    const toggleMenu = () => {
        const newValue = !menuOpen;
        setMenu(newValue);
        onMenuOpen(newValue)
    }
    return (
        <>
            <div className={`${styles.socialbuttonMenu} ${menuOpen ? styles.open : ""}`}
                onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className={`${styles.navMenu} ${menuOpen ? styles.open : ""}`}>
                <Link to={"/"}><div>Etusivu</div></Link>
                <Link to={"/info"}><div>Tietoja</div></Link>

            </div>
        </>
    )
}
const SocialButtons = ({ onMenuOpen }) => {
    return <SocialMenu onMenuOpen={onMenuOpen}></SocialMenu>


};


export {
    SocialButtons,
    SocialLink
}
//{buttons()}
/*            
*/