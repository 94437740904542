import React, { useEffect, useState } from 'react'
import styles from "./header.module.css"
import { Link } from 'react-router-dom';
import { SocialButtons } from './socialbuttons';
const Header = (props) => {
    const [home, setHome] = useState(props.home);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isAtTop, setTop] = useState(true);

    // Window width adjustment
    const [isMobile, setMobile] = useState(
        window.matchMedia("(max-width: 740px)").matches
    );

    const updateMedia = () => {
        setMobile(window.matchMedia("(max-width: 740px)").matches);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });


    const onMenuOpen = open => {
        setMenuOpen(open)
    }

    useEffect(() => {
        const onScroll = e => {
            setTop(window.scrollY <= (props.home ? 25 : 6))
        }

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    });



    return (
        <nav className={`${styles.headerNav} ${(isAtTop || menuOpen) ? "" : styles.transparent}`}>

            <div className={styles.headerItems}>
                {home ? <>
                    <Link to="/" className={styles.returnButton}><div><i className={styles.returnArrow}></i> Takaisin</div></Link>
                    <Link to="/" className={styles.returnButtonSmall}><div><i className={styles.returnArrow}></i></div></Link>
                </>
                    : <div className={styles.headerFlexFiller}></div>}
                {isMobile ?
                    <Link to="/" className={styles.headerText}>
                        <h1>
                            <b>Pentti&nbsp;Otsamo</b>
                            <br />
                            comics&nbsp;&&nbsp;illustrations
                        </h1>

                    </Link>

                    :
                    <Link to="/" className={styles.headerText}><h1><b>Pentti&nbsp;Otsamo</b> comics&nbsp;&&nbsp;illustrations</h1></Link>
                }
                <SocialButtons onMenuOpen={onMenuOpen} />

            </div>


        </nav>
    )
};

export default Header