import React from 'react'
import Carousel from "./carousel"
import { getProject, getProjectImages } from '../../api'
import Linkify from 'react-linkify/dist/components/Linkify'

import Footer from '../Footer/footer'

class Viewer extends React.Component {
    constructor(props) {
        super(props)
        this.initialized = false;
        this.state = { id: props.id, project: {}, images: [], activeSlide: 0, activeDescription: "" }

        this.onChangeActiveSlide = this.onChangeActiveSlide.bind(this)
    }


    componentDidMount() {
        window.scrollTo({ top: 0 })
        getProject(this.state.id).then(project => {
            document.title = `${project.title} | Pentti Otsamo`;
            this.setState({ project: project }
            )
        })

        getProjectImages(this.state.id).then(images => {
            if (images.length > 0) {
                this.setState({ images: images, activeDescription: images[0].description })
            }
            else {
                this.setState({ images: images, activeDescription: "" })

            }
            this.initialized = true;
            this.forceUpdate();
        })
    }

    onChangeActiveSlide(idx) {
        this.setState({ activeSlide: idx })
        if (this.initialized) {
            this.setState({ activeDescription: this.state.images[this.state.activeSlide].description })

        }

    }

    //                    

    render() {
        return (

            <div id="image-viewer">

                <div id="carousel-container" onScroll={function () { return }}>
                    <Carousel id={this.state.id} projectTitle={this.state.project.title} images={this.state.images} onChangeSlide={this.onChangeActiveSlide} />
                </div>
                <div className="description">
                    <div className="description-text">
                        <h2>{this.state.project.title}</h2>
                        <Linkify>
                            <h3>{this.state.project.subtitle}</h3>
                        </Linkify>

                        <Linkify>
                            {(this.state.activeDescription)}
                        </Linkify>
                    </div>
                    <Footer />
                </div>


            </div>

        )
    }
}

export default Viewer