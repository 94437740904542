import React, { Component, useEffect } from 'react';
import Header from './components/Header/header';
import Grid from './components/Grid/grid';
import Viewer from './components/Viewer/viewer';
import Info from "./components/Info/info";
import { MarginFooter } from './components/Footer/footer';

import {
  Routes,
  Route,
  useParams,
} from "react-router-dom";


class App extends Component {

  state = {
    projects: []
  }
  constructor(props) {
    super(props);
  };




  componentDidMount() {
    var apiURL = "/api/project";

    fetch(apiURL)
      .then(res => res.json())
      .then((data) => {
        this.setState({ projects: data });
      })
      .catch(console.log);
  }


  render() {
    return (
      <div id="App">

        <Routes>
          <Route exact path="/" element={<GridPage />} />
          <Route exact path="/info" element={<InfoPage />} />

          <Route path="/view/:id" element={<ViewerPage />} />
        </Routes>
      </div>
    )
  }
}

function GridPage() {
  useEffect(() => {
    document.title = "Kuvittaja Pentti Otsamo";
  });

  return (
    <>
      <Header home={false}></Header>
      <Grid></Grid>
      <MarginFooter />

    </>
  )
}




function InfoPage() {
  useEffect(() => {
    document.title = "Kuvittaja Pentti Otsamo";
  });

  return (
    <>
      <Header home={true}></Header>
      <Info></Info>
    </>
  )
}

function ViewerPage() {
  const { id } = useParams();

  return (
    <>
      <Header home={true}></Header>
      <Viewer id={id}></Viewer>
    </>
  );
}



export default App